import { Pipe, PipeTransform } from '@angular/core';
import { UploadService } from '@app/shared/services/upload.service';
import { Observable } from 'rxjs';

@Pipe({
  name: 'imgUrl',
  standalone: true,
})
export class UploadedImageUrlPipe implements PipeTransform {
  constructor(private uploadService: UploadService) {}

  transform(imageId, systemObjectType): Observable<any> {
    return this.uploadService.getImageUrlSanitized(imageId, systemObjectType);
  }
}
